<template>
  <div>
    <b-modal
      id="back-to-homepage"
      ref="back-to-homepage"
      dialog-class="global-popup-dialog"
      content-class="global-popup-content"
      header-class="global-popup-header"
      body-class="global-popup-body"
      hide-footer
      v-model="isShow"
      :no-close-on-backdrop="true"
    >
      <div class="modal-img">
        <img src="../../assets/images/modal-img.png" alt="" />
      </div>
      <div class="d-block text-center global-popup-title">
        {{ this.popupsTitle }}
      </div>
      <div class="global-popup-button">
        <b-button v-if="this.popupsbuttomA" class="mt-3 btn-secondary" block @click="NObutton">{{
          this.popupsbuttomA
        }}</b-button>
        <b-button v-if="this.popupsbuttomB" class="mt-3 btn-success" block @click.stop="OKbutton">{{
          this.popupsbuttomB
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    popupsTitle: {
      type: String,
      default: "",
    },
    popupsbuttomA: {
      type: String,
      default: "",
    },
    popupsbuttomB: {
      type: String,
      default: "",
    },
    componentPopupId: {
      default: 0,
    },
    isShow: {
      default: false,
    },
  },
  mounted() {},
  methods: {
    OKbutton() {
      this.$emit("OKbutton");
    },
    NObutton() {
      this.$emit("NObutton");
    },
  },
};
</script>
<style lang="scss">
.global-popup-content {
  border-radius: 10px;
  border: none;
}
.global-popup-header,
.global-popup-footer {
  border: none;
  display: none !important;
}
.global-popup-button {
  justify-content: flex-end;
  .btn {
    margin:  1rem;
  }
  .btn-secondary {
    width: 27%;
    font-size: 1.15rem;
    font-weight: bold;
    color: #333;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #dcdfe6;
  }
  .btn-success {
    width: 27%;
    font-size: 1.15rem;
    font-weight: bold;
    color: #fff;
    background-color: #2bb774 !important;
    border-radius: 30px;
    border: 1px solid #2bb774 !important;
  }
}
.global-popup-body {
  text-align: center;
  padding: 0 !important;
  padding-bottom: rem;
  .icongantanhao {
    color: #fbb860;
    font-size: 5.5vw;
  }
}
.global-popup-button {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.global-popup-dialog {
  height: 100%;
  display: flex;
  align-items: center;
}
.modal-img {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  img {
    position: absolute;
    width: 21%;
    top: -43px;
  }
}
.global-popup-title {
  padding: 1rem 2rem;
}
.options {
  .bv-no-focus-ring {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0;
    .custom-control-label {
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
