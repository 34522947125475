<template>
  <div class="grammar-directory-card">
    <b-row style="padding: 3.5rem 0.3rem 0.5rem 0.3rem">
      <b-col>
        <!-- <span class="iconfont icon-shijian1"></span> -->
        <span>预期时间：</span>
        <span class="time">{{ cardData.expectedMinutes }}分钟</span>
      </b-col>
      <b-col>
        <!-- <span class="iconfont icon-shijian"></span> -->
        <span>实际用时：</span>
        <span class="time">{{ cardData.actualMinutes }}分钟</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right base-text-right">
        <globalButtonDirectory
          @beginTest="beginTest"
          :starNum="cardData.star"
          :state="cardData.state"
        ></globalButtonDirectory>
      </b-col>
    </b-row>
    <div class="card-question">
      <div class="card-title">
        <div class="card-title-text">{{ cardData.trainingExamName }}</div>
      </div>
      <div @click="clickExplain(cardData.grammerTagId)" class="card-explain">
        <span class="iconfont icon-peizhizidingyi-shujutishikuang"></span>
        <span>讲解</span>
      </div>
      <b-modal
        centered 
        hide-footer
        v-model="explainShow"
        body-class="explain-body"
        dialog-class="explain-dialog"
        content-class="explain-content"
        header-class="explain-header"
        :title='dataExplain.answerKey'
      >
        <span v-html="dataExplain.body"></span
      ></b-modal>
    </div>
  </div>
</template>

<script>
import globalButtonDirectory from "@/components/global-button/global-button-directory.vue";
import { getExplain } from "@/api/english-small";
export default {
  data() {
    return {
      explainShow: false,
      dataExplain: {},
    };
  },
  components: { globalButtonDirectory },
  props: {
    sprintProgress: {
      default: 1,
    },
    cardData: {
      default: function () {
        return {};
      },
    },
  },
  methods: {
    beginTest() {
      this.$emit("beginTest");
    },
    clickExplain(tagId) {
      this.explainShow = !this.explainShow;
      let parmeter = {
        sequence: tagId,
        questionId: "dc957680-bdbf-4231-99c1-e4642546eeda",
      };
      getExplain(parmeter).then((res) => {
        this.dataExplain = res.data.result;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grammar-directory-card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  margin: 32px 0 15px;
  padding: 0 0 10px;
}
.card-question {
  width: 90%;
  position: absolute;
  top: -13px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-title {
  width: 177px;
  height: 40px;
  background: #2cb774;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  transform: skew(-20deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-title-text {
  transform: skew(20deg);
  color: #fff;
  font-size: 1.15rem;
}
.card-explain {
  cursor: pointer;
  .iconfont {
    color: #f7a901;
    padding-right: 3px;
  }
  & > span:nth-child(2) {
    color: #333;
    text-decoration: underline;
  }
}
.begin-test-button {
  margin: 10px;
}
.icon-shijian1,
.icon-shijian {
  color: #ffc13b;
  font-size: 1.4rem;
  padding-right: 0.2rem;
}
.time {
  color: #f7a901;
}
</style>
<style lang="scss">
.base-text-right {
  .col {
    text-align: center;
    // padding-bottom: 0rem !important;
  }
}
.explain-dialog {
  max-width: 70rem;
}
.explain-content {
  height: 45rem;
  position: absolute;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.explain-body {
  overflow-x: hidden;
  height: 45rem;
}
.explain-header {
  background-color: #2cb774;
  color: #fff;
  .close:hover,.close {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    text-shadow: none;
  }
}
</style>