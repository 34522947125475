<template>
  <b-container fluid class="grammar-directory-page">
    <b-row class="page-top">
      <b-col class="text-left">语法与功能</b-col>
      <b-col
        class="text-right"
        v-show="permission.indexOf('Plys.English.MiddleSchool.CanSkip') !== -1"
      >
        <GlobalButtonParallelogram
          :sprintId="2"
          :tagId="2"
        ></GlobalButtonParallelogram>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="item in grammarList"
        :key="item.trainingExamId"
        lg="3"
        md="6"
        xs="12"
      >
        <GrammarDirectoryCard
          :cardData="item"
          @beginTest="beginTest(item)"
        ></GrammarDirectoryCard>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GlobalButtonParallelogram from "@/components/global-button/global-button-parallelogram.vue";
import GrammarDirectoryCard from "./components/grammar-directory-card.vue";
import { getGrammarTraining } from "@/api/english-small";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      grammarList: [],
      tagId: "",
    };
  },
  components: {
    GlobalButtonParallelogram,
    GrammarDirectoryCard,
  },
  created() {
    this.getGrammarList();
  },
  computed: {
    ...mapState(["permission"]),
  },
  methods: {
    ...mapMutations(["setTrainingData"]),
    beginTest(item) {
      let trainingData = {
        tagId: this.tagId,
        grammerTagId: item.grammerTagId,
        setTagId: "1",
        difficultyTagId: "",
        summaryId: item.summaryId,
      };
      this.setTrainingData(trainingData);
      this.$router.push({
        name: "SmallModuleSprintTest",
        query: {
          trainingExamId: item.trainingExamId,
          // module: 1,
          type: 2,
          trainingExamName: item.trainingExamName,
          sprintId: 2,
        },
      });
    },
    getGrammarList() {
      getGrammarTraining({}).then((res) => {
        console.log("语法训练页", res);
        this.grammarList = res.data.result.grammerTrainings;
        this.tagId = res.data.result.tagId;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grammar-directory-page {
  max-width: 1445px;
  background: white;
  min-height: 550px;
  padding: 20px 15px;
}
.page-top {
  margin: 10px 0 20px;
}
.text-left {
  font-size: 1.5rem;
}
</style>