<template>
  <div>
    <b-button @click="afterClick"  :class="[buttonType, buttonColor]">
      <div class="button-content">
        <span class="button-text">{{ buttonText }}</span>
        <span :class="['iconfont', icon]"></span>
      </div>
    </b-button>
    <GlobalPopup
      :popupsTitle="porpContent.popupsTitle"
      :popupsbuttomA="porpContent.popupsbuttomA"
      :popupsbuttomB="porpContent.popupsbuttomB"
      @OKbutton="okButton"
      @NObutton="
        () => {
          modalShow = false;
        }
      "
      :isShow="modalShow"
    ></GlobalPopup>
  </div>
</template>

<script>
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import { jumpSprint } from "@/api/english-small";
export default {
  components: { GlobalPopup },

  data() {
    return {
      porpContent: "",
      modalShow: false,
    };
  },
  props: {
    buttonText: {
      default: "跳跃关卡",
    },
    buttonType: {
      default: "line",
    },
    buttonColor: {
      default: "",
    },
    icon: {
      default: "icon-jiantouarrowheads3",
    },
    sprintId: {
      default:0
    },
    tagId:{
      default:""
    }
  },
  methods: {
    afterClick() {
      this.porpContent = {
        popupsTitle:
          "选择跳过本关卡吗？确认后将取消本模块剩余试题的培优币奖励，并无法返回继续训练!",
        popupsbuttomA: "不跳过",
        popupsbuttomB: "跳过",
      };
      this.modalShow = true;
      this.okButton = () => {
        this.modalShow = false;
        this.moduleSkip();
      };
    },
    okButton() {},
    moduleSkip() {
      jumpSprint({ sprintId: this.sprintId, tagId: this.tagId }).then(() => {
        this.$router.replace({
          name: "HomeIndex",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.iconline {
  background: none;
  outline: none;
  border: none;
  .button-text {
    margin: 5px;
    border-bottom: 1px solid;
  }
}
.line {
  background: none;
  border: 2px solid #2cb774;
  outline: none;
  transform: skew(-20deg);
  color: #2cb774;
  padding: 0.3rem 1rem;
  font-size: 16px;
  letter-spacing: 1px;
}
.mock-color {
  display: flex;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: #fff;
  background: #2CB774;
border: 2px solid #2CB774;
}
.button-content {
  transform: skew(20deg);
  display: flex;
  align-items: center;
  .icon-jiantouarrowheads3 {
    font-weight: 400;
    font-size: 18px;
  }
}
</style>>

