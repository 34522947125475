<template>
  <b-container>
    <b-row class="button">
      <b-col :class="state === 4 ? 'star-padding' : ''">
        <b-button
          v-if="state === 0"
          class="lock-button"
          disabled
          variant="outline-secondary"
        >
          <span>训练</span>
          <span class="iconfont icon-suo1beifen"></span>
        </b-button>
        <b-button
          @click="beginTest"
          v-if="state === 1"
          class="begin-button"
          variant="outline-secondary"
        >
          <span>训练</span>
          <span class="iconfont icon-bianji"></span>
        </b-button>
        <b-button
          @click="beginTest"
          v-if="state === 2"
          class="continue-button"
          variant="outline-secondary"
        >
          <span>继续</span>
          <span class="iconfont icon-bianji"></span>
        </b-button>
        <div class="star-button-group" v-if="state === 3">
          <b-form-rating
         
          inline
          disabled
          no-border
          size="lg"
          stars="3"
          v-model="starNum"
          class="star-class"
        ></b-form-rating>
        <b-button
          @click="beginTest"
          
          class="redo-button"
          variant="outline-secondary"
        >
          <span>重做</span>
          <span class="iconfont icon-bianji"></span>
        </b-button>
        </div>
        
        <b-form-rating
          v-if="state === 4"
          inline
          disabled
          no-border
          size="lg"
          stars="3"
          v-model="starNum"
          class="aaa"
        ></b-form-rating>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    state: {
      default: 0,
    },
    starNum: {
      default: 2,
    },
  },
  methods: {
    beginTest() {
      this.$emit("beginTest");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-bianji {
  color: #ffc13b;
}
.iconfont {
  padding-left: 5px;
}
.btn {
  padding: 1px 6px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.b-rating.disabled,
.b-rating:disabled {
  background: none;
  color: #ffc13b;
}
.button {
  .col {
    padding: 1rem;
  }
  .star-padding {
    padding: 0.49rem;
  }
}
.star-button-group{
  display: flex;
  align-items: center;
  justify-content: center;
}
.star-class {
  padding: 0;
  height: calc(0em + 1rem + 2px);
}
</style>